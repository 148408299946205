import { inject, Injectable } from '@angular/core';
import { Lap } from '@models/lap';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LapService {

  private readonly translateService = inject(TranslateService);

  currentLapInformation(lap: Lap | null): string | null {
    if (lap) {
      const information = this.translateService.instant('live.title', { lapNumber: lap.lapNumber });

      const transit = lap.duration ? 'live.duration' :
        lap.finishTime ? 'live.finishTime' :
          lap.transitTime4 ? 'live.transitTime4' :
            lap.transitTime3 ? 'live.transitTime3' :
              lap.transitTime2 ? 'live.transitTime2' :
                lap.transitTime1 ? 'live.transitTime1' :
                  lap.startTime ? 'live.startTime' : undefined;

      return transit ? `${information}, ${this.translateService.instant(transit, { duration: lap.duration })}` : information;
    }

    return null;
  }

  getImageForLap(lap: Lap | null): string | null {
    if (!lap) {
      return null;
    }

    if (lap.lapNumber > 6) {
      return this.getImageForTimeRegistration(6, 6);

    } else {
      const lapNumber = (lap && lap.lapNumber) || 0;
      let timeRegistrationNumber = 0;

      if (lap.finishTime) {
        timeRegistrationNumber = 6;
      } else if (lap.transitTime4) {
        timeRegistrationNumber = 5;
      } else if (lap.transitTime3) {
        timeRegistrationNumber = 4;
      } else if (lap.transitTime2) {
        timeRegistrationNumber = 3;
      } else if (lap.transitTime1) {
        timeRegistrationNumber = 2;
      } else if (lap.startTime) {
        timeRegistrationNumber = 1;
      }

      return this.getImageForTimeRegistration(lapNumber, timeRegistrationNumber);
    }
  }

  getImageForTimeRegistration(lapNumber: number, timeRegistrationNumber: number): string {
    return `assets/lap/${lapNumber}.${timeRegistrationNumber}.png`;
  }
}
