import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Constants } from '@constant/constants';
import { environment } from '@environments/environment';
import { Lap } from '@models/lap';
import { Participant } from '@models/participant';
import { PushType } from '@models/push-notification';
import { SearchOperator, Searchrequest } from '@models/search-request';
import { SearchResult } from '@models/search-result';
import { TranslateService } from '@ngx-translate/core';
import { AppDataService } from '@services/app-data.service';
import { GenericHttpService } from '@services/generic-http.service';
import { PopupService } from '@services/popup-service';
import { PushService } from '@services/push.service';
import {
  catchError, firstValueFrom, forkJoin, Observable, of, switchMap,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

import { LapService } from './lap.service';

@Injectable({ providedIn: 'root' })
export class ParticipantService extends GenericHttpService {
  private readonly appDataService = inject(AppDataService);
  private readonly httpClient = inject(HttpClient);
  private readonly lapService = inject(LapService);
  private readonly popupService = inject(PopupService);
  private readonly pushService = inject(PushService);
  private readonly translateService = inject(TranslateService);

  private readonly baseUrl = `${environment.url}/participants`;
  private me = this.appDataService.me;
  favorites = this.appDataService.favorites;

  private static getLastUpdatedTime({
    startTime,
    transitTime1,
    transitTime2,
    transitTime3,
    transitTime4,
    finishTime,
  }: Lap) {
    const [lastUpdatedTime] = [
      startTime,
      transitTime1,
      transitTime2,
      transitTime3,
      transitTime4,
      finishTime,
    ]
      .filter(time => typeof time === 'string')
      .reverse();

    return lastUpdatedTime;
  }

  private addFavorite(favorites: Participant | Participant[]): void {
    const favoritesToAdd: Participant[] = (Array.isArray(favorites) ? favorites : [favorites])
      .filter(p => this.findFavorite(p.uuid) === undefined);

    this.appDataService.setFavorites([
      ...this.favorites()!,
      ...favoritesToAdd,
    ]);
  }

  private enrichParticipant(participant: Participant): Participant {
    if (participant) {
      if (participant.subEventUuids && this.appDataService.event() && this.appDataService.event()!.subEvents.length) {
        const participantEvents: string[] = [];

        this.appDataService.event()!.subEvents.forEach(subEvent => {
          if (participant.subEventUuids.includes(subEvent.uuid)) {
            participantEvents.push(subEvent.name);
          }
        });

        participant.eventDescription = participantEvents.toString();
      }

      participant.unreadPassages = [];
    }

    return participant;
  }

  private findFavorite(uuid: string, favorites?: Participant[]): Participant | undefined {
    const favs = favorites ?? this.favorites();

    return favs?.find(favorite => favorite.uuid === uuid);
  }

  private findParticipants(searchText: string): Observable<Participant[]> {
    const url = `${this.baseUrl}/findParticipants`;
    const params = new HttpParams()
      .set('page', 0)
      .set('size', Constants.searchPageSize);

    return this.httpClient.post<SearchResult<Participant>>(url, searchText, { params })
      .pipe(
        distinctUntilChanged(),
        debounceTime(600),
        map(result => result.content
          .map(participant => {
            participant = this.enrichParticipant(participant);

            // If favorites are already registered, update the result list to indicate a favorite
            if (this.findFavorite(participant.uuid)) {
              participant.favorite = true;
            }

            return participant;
          }),
        ),
        catchError(error => {
          this.handleError(error);
          if (error.status === 404) {
            this.popupService.openToastError(this.translateService.instant('error.notfound.participant'));
          }
          throw error;
        }),
      );
  }

  // Not yet used:
  private follow(participant: Participant): void {
    const url = `${this.baseUrl}/${participant.uuid}/follow`;

    this.httpClient.get(url).pipe(
      catchError(error => {
        this.handleError(error);
        if (error.status === 404) {
          this.popupService.openToastError(this.translateService.instant('error.notfound.participant'));
        }
        throw error;
      }),
    ).subscribe();
  }

  private async loadFavorites(): Promise<void> {
    const favoriteUuids = this.favorites()
      ?.map(favorite => favorite.uuid) ?? [];

    if (favoriteUuids.length > 0) {
      let httpParams = new HttpParams();
      favoriteUuids.forEach(favoriteUuid => {
        httpParams = httpParams.append('uuid', favoriteUuid);
      });

      this.httpClient.get<Participant[]>(`${this.baseUrl}/by/uuid`, { params: httpParams })
        .pipe(
          filter(participants => participants && participants.length > 0),
          map(participants => participants
            .filter(participant => Object.prototype.hasOwnProperty.call(participant, 'uuid'))
            .map(participant => {
              participant.favorite = true;
              participant.me = (participant.uuid === this.me()?.uuid);
              const passage = this.favorites()?.find(d => d.uuid === participant.uuid);
              if (passage) {
                participant.unreadPassages = passage.unreadPassages;
              }

              return participant;
            })),
          switchMap(participants => this.getBundledLapTimes(participants)),
          catchError(error => {
            this.handleError(error);
            if (error.status === 404) {
              this.popupService.openToastError(this.translateService.instant('error.notfound.favorite'));
            }
            throw error;
          }),
        )
        .subscribe({
          next: participants => {
            this.addFavorite(participants);
          },
        });
    }
  }

  private search(searchrequest: Searchrequest): Observable<SearchResult<Participant>> {
    return this.httpClient.post<SearchResult<Participant>>(`${this.baseUrl}/search`, searchrequest)
      .pipe(
        map(list => {
          list.content.forEach(participant => {
            participant = this.enrichParticipant(participant);

            // If favorites are already registered, update the result list to indicate a favorite
            if (this.findFavorite(participant.uuid)) {
              participant.favorite = true;
            }
          });

          return list;
        }),
        catchError(error => {
          this.handleError(error);
          if (error.status === 404) {
            this.popupService.openToastError(this.translateService.instant('error.notfound.participant'));
          }
          throw error;
        }),
      );
  }

  // Not yet used:
  private unfollow(participant: Participant): void {
    const url = `${this.baseUrl}/${participant.uuid}/unfollow`;

    this.httpClient.get(url).pipe(
      catchError(error => {
        this.handleError(error);
        if (error.status === 404) {
          this.popupService.openToastError(this.translateService.instant('error.notfound.participant'));
        }
        throw error;
      }),
    ).subscribe();
  }

  activate(me: Participant, code: string): Participant {
    // Activate me as favorite
    me.favorite = true;
    me.code = code;
    me.me = true;

    // TODO: Go to backend to activate me
    // If backend returns OK then ...
    // Store my UUID
    // TODO: assign me via function in service
    this.appDataService.setMe(me);

    this.toggleFavorite(me);
    // Disable the option to register
    this.appDataService.setSkipRegistration(true);

    return me;
  }

  get(uuid: string): Observable<Participant> {
    let foundFavorite = this.findFavorite(uuid);
    if (foundFavorite) {
      foundFavorite = this.enrichParticipant(foundFavorite);

      return of(foundFavorite);
    }

    return this.httpClient.get<Participant>(`${this.baseUrl}/${uuid}`).pipe(
      map(participant => this.enrichParticipant(participant)),
      catchError(error => {
        this.handleError(error);
        if (error.status === 404) {
          this.popupService.openToastError(this.translateService.instant('error.notfound.participant'));
        }
        throw error;
      }),
    );
  }

  getBundledLapTimes(participantList: Participant[]): Observable<Participant[]> {
    const url = `${this.baseUrl}/laps`;
    let httpParams = new HttpParams();

    participantList.forEach(({ uuid }) => {
      httpParams = httpParams.append('uuid', uuid);
    });

    return this.httpClient.get<Lap[][]>(url, { params: httpParams })
      .pipe(
        map((lapsArray = []) => {
          if (!Array.isArray(lapsArray)) {
            lapsArray = [];
          }

          // Loop over participants to make sure every participant gets updated (even if no laps)
          return participantList.map(participant => {
            const lapsForParticipant = lapsArray.find(laps => laps.length > 0 && participant.uuid === laps[0].participantUuid);
            if (lapsForParticipant) {
              participant.laps = lapsForParticipant
                .map(lap => ({
                  ...lap,

                  lapNumber: lap['number'],
                }))
                .sort((a, b) => (b.lapNumber - a.lapNumber));
              participant.currentLap = (participant.laps && participant.laps[0]);
              participant.lastUpdated = participant.currentLap ? ParticipantService.getLastUpdatedTime(participant.currentLap) : '';
            } else {
              participant.laps = [];
              participant.currentLap = null;
              participant.lastUpdated = null;
            }

            return participant;
          });
        }),
        catchError(error => {
          // If this call does not exist yet, try to get the Laps the old way, by using separate calls
          if (error.status === 404) {
            const participants$ = participantList.map(participant => this.getLapTimes(participant));
            return forkJoin(participants$);
          }
          return of([]);
        }),
      );
  }

  getLapTimes(participant: Participant): Observable<Participant> {
    const url = `${this.baseUrl}/${participant.uuid}/laps`;

    return this.httpClient.get<Lap[]>(url)
      .pipe(
        map(laps => {

          laps.forEach(lap => lap.lapNumber = lap['number']);
          participant.laps = laps.sort((a, b) => (b.lapNumber - a.lapNumber));
          participant.currentLap = (participant && participant.laps[0]);

          if (participant.currentLap) {
            participant.lastUpdated = ParticipantService.getLastUpdatedTime(participant.currentLap);
          }

          return participant;
        }),
        catchError(error => {
          this.handleError(error);
          if (error.status === 404) {
            this.popupService.openToastError('Laps not found');
          }
          throw error;
        }),
      );
  }

  getParticipantsOfTeam(uuid: string): Observable<Participant[]> {
    const req: Searchrequest = {
      filter: {
        type: 'AND',
        fields: [
          {
            fieldName: 'team.uuid',
            operator: SearchOperator.EQ,
            value: uuid,
          },
          {
            fieldName: 'startNumber',
            operator: SearchOperator.GT,
            value: 0,
          },
        ],
      },
    };

    return this.httpClient.post<SearchResult<Participant>>(`${this.baseUrl}/find`, req)
      .pipe(
        distinctUntilChanged(),
        debounceTime(600),
        map(result => result.content),
        map(result => result.map(participant => {
          participant.favorite = !!this.findFavorite(participant.uuid);

          return participant;
        })),
        catchError(error => {
          this.handleError(error);
          if (error.status === 404) {
            this.popupService.openToastError(this.translateService.instant('error.notfound.teams'));
          }
          throw error;
        }),
      );
  }

  async initialize(): Promise<void> {
    await this.loadFavorites();

    console.log('Initialized ParticipantService');
  }

  register(email: string): Participant | null {
    // TODO: Go to backend to register me by email
    // For now, let's search Wim ;-) (startNumber=2277)
    // let me: Participant;

    const searchrequest: Searchrequest = {
      filter: {
        type: 'AND',
        fields: [
          {
            fieldName: 'name',
            operator: SearchOperator.CONTAINS,
            value: email,
          },
        ],
      },
    };

    this.search(searchrequest)
      .subscribe({
        next: result => {
          // me = result[0];
          console.log(result);
        },
      });

    return null;
  }

  removeFavorite(participant: Participant): void {
    this.appDataService.setFavorites(this.favorites()?.filter(f => f.uuid !== participant.uuid) || []);
  }

  searchByText(text: string): Observable<Participant[]> {
    return this.findParticipants(text);
  }

  subscribeToAllFavorites(): void {
    this.pushService.subscribeToAllFavorites();
  }

  async toggleFavorite(participant: Participant): Promise<void> {
    const participantLogicEnabled = this.appDataService.eventSettings()!.enableParticipantLogic;
    let participantAdded: boolean | undefined = undefined;

    if (participant.favorite) {
      participant = await firstValueFrom(this.getLapTimes(participant));

      if (!this.findFavorite(participant.uuid)) {
        if (participant.me) {
          this.removeFavorite(participant);
        } else {
          this.addFavorite(participant);
          participantAdded = true;
        }
      }

      // Do not subscribe to and follow myself
      if (!participant.me) {
        this.pushService.togglePushSetting(PushType.LAP, true);
        this.pushService.subscribeToFavorite(participant)
          .then(() => {
            if (participantLogicEnabled) {
              // TODO: follow not implemented on backend yet
              // this.follow(participant);
            }
          });
      }
    } else {
      if (this.findFavorite(participant.uuid)) {
        participantAdded = false;
        this.removeFavorite(participant);
        this.pushService.unsubscribeFromFavorite(participant);
        if (participantLogicEnabled) {
          // this.unfollow(participant);
        }
      }
    }

    if (participantAdded !== undefined) {
      const text = this.translateService.instant(participantAdded ? 'text.participant-added' : 'text.participant-removed',
        { participant: participant.name });
      await this.popupService.openToastMessage(text);
    }
  }

  unsubscribeFromAllFavorites(): void {
    this.pushService.unsubscribeFromAllFavorites();
  }
}
